import React from "react";

import SplitHero from "../../components/split-hero";
import Text from "../../components/text";

import gatsbyImage from "../../images/gatsby-logo.svg";

function JamStackSection() {
  return (
    <SplitHero>
      <SplitHero.Media>
        <SplitHero.Media.Content>
          <SplitHero.Media.Img src={gatsbyImage} alt="Gatsby" />
        </SplitHero.Media.Content>
      </SplitHero.Media>

      <SplitHero.Content>
        <Text variant="h3" component="h2" shade="light">
          JAMstack.
        </Text>
        <Text variant="p" shade="mid">
          I have experience of creating headless front-ends, and working with
          the JAMstack. This website is built with Gatsby a static site
          framework based on React.
        </Text>
      </SplitHero.Content>
    </SplitHero>
  );
}

export default JamStackSection;
