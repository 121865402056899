import styled from "styled-components";

const SplitHero = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  @media (min-width: 1140px) {
    flex-direction: row;
  }
`;

SplitHero.Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  align-items: flex-start;
  order: 2;
  padding: ${({ theme }) => theme.spacing(5)}px 0;
  position: relative;
  width: 100%;
  z-index: 2;

  &:first-child:after {
    right: 0;
  }

  &:last-child:after {
    left: 0;
  }

  @media (min-width: 1140px) {
    justify-content: center;
    order: 1;
    padding: ${({ theme }) => theme.spacing(6)}px;
    width: 50%;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
`;

SplitHero.Media = styled.div`
  background-color: ${({ theme }) => theme.palette.background.greyLight};
  border-radius: 8px;
  order: 1;
  overflow: hidden;
  position: relative;
  width: 100%;

  &:after {
    content: "";
    display: block;
    padding-bottom: 56.25%;
  }

  @media (min-width: 1140px) {
    width: 50%;
  }
`;

SplitHero.Media.Content = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0%;
`;

SplitHero.Media.Img = styled.img`
  height: 33%;

  @media (min-width: 1140px) {
    height: 22%;
  }
`;

SplitHero.Heading = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  width: 100%;

  @media (min-width: 1140px) {
    align-items: center;
    flex-direction: row;
    margin-bottom: 0;
  }
`;

export default SplitHero;
